<template>
  <div>
    <div class="section">
      <div class="container">
        <validation-observer
          ref="customFieldsForm"
          v-slot="{ invalid, handleSubmit }"
        >
          <form
            @submit.prevent="handleSubmit($emit('save-fields'))"
          >
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="label"
            >
              <b-field
                horizontal
                label="Label"
                :type="{ 'is-danger': errors[0] }"
                :message="errors"
                class="is-required"
              >
                <b-input
                  v-model="label"
                />
              </b-field>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="objectName"
            >
              <b-field
                horizontal
                label="Object Name"
                :type="{ 'is-danger': errors[0] }"
                :message="errors"
                class="is-required"
              >
                <b-select
                  v-model="objectName"
                  placeholder="Select Object Type"
                  :disabled="isEditing"
                >
                  <option
                    v-for="option in objectIds"
                    :key="option.id"
                    :value="option.code"
                  >
                    {{ option.name }}
                  </option>
                </b-select>
              </b-field>
            </validation-provider>
            <b-field
              horizontal
              label="Is Required"
            >
              <b-checkbox v-model="isRequired" />
            </b-field>
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="fieldType"
            >
              <b-field
                horizontal
                label="Field Type"
                :type="{ 'is-danger': errors[0] }"
                :message="errors"
                class="is-required"
              >
                <b-select
                  v-model="fieldType"
                  placeholder="Select field type"
                  :disabled="isEditing"
                >
                  <option
                    v-for="option in fieldTypes"
                    :key="option.id"
                    :value="option.code"
                  >
                    {{ option.name }}
                  </option>
                </b-select>
              </b-field>
            </validation-provider>
            <validation-provider
              v-if="fieldType === 'DROP_DOWN' || fieldType === 'MULTI_SELECT'"
              v-slot="{ errors }"
              :rules="{required: fieldType === 'DROP_DOWN' || fieldType === 'MULTI_SELECT'}"
              name="lookups"
            >
              <b-field
                horizontal
                label="Lookups"
                :type="{ 'is-danger': errors[0] }"
                :message="errors"
                class="is-required"
              >
                <div class="columns">
                  <div class="column is-3-desktop is-6 tablet">
                    <b-select
                      v-model="lookups"
                      placeholder="Select lookups"
                      :disabled="isEditing"
                      expanded
                    >
                      <option
                        v-for="(option, index) in lookupCodes"
                        :key="index"
                        :value="option"
                      >
                        {{ option }}
                      </option>
                    </b-select>
                  </div>
                  <div class="column">
                    <b-button
                      type="is-info"
                      :disabled="isEditing"
                      @click="$emit('move-to-lookups')"
                    >
                      Add Lookup Items
                    </b-button>
                  </div>
                </div>
              </b-field>
            </validation-provider>

            <b-button
              v-if="isEditing === false"
              :disabled="invalid"
              type="is-info"
              icon-left="plus"
              aria-label="Add Field"
              native-type="submit"
            >
              Add Field
            </b-button>

            <b-button
              v-else-if="isEditing === true"
              :disabled="invalid"
              type="is-success"
              icon-left="plus"
              aria-label="Add Field"
              @click="updateField"
            >
              Save Field
            </b-button>
          </form>
        </validation-observer>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <custom-fields-table
          ref="customFieldsTable"
          v-click-outside-dg="{
            handler: 'onClickOutsideTable',
            exclude: [
              'inlineEditingSavePromptCF',
              'customFieldsForm',
            ],
          }"
          @is-editing="(val) => isEditing = val"
        />
      </div>
    </div>

    <b-modal
      ref="inlineEditingSavePromptCF"
      v-model="isSavePromptActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="inline editing save modal"
      aria-modal
    >
      <template #default="props">
        <inline-editing-save-prompt
          @save="updateField"
          @close="props.close"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { InlineEditingSavePrompt } from '@/components/Shared';
import CustomFieldsTable from './CustomFieldsTable.vue';

export default {
  name: 'CustomFieldsForm',

  components: {
    ValidationObserver,
    ValidationProvider,
    CustomFieldsTable,
    InlineEditingSavePrompt,
  },

  data: () => ({
    isLoading: true,
    isEditing: false,
    isSavePromptActive: false,
    fieldTypes: [
      {
        id: 201,
        name: 'text',
        code: 'TEXT_INPUT',
      },
      {
        id: 204,
        name: 'currency',
        code: 'CURRENCY',
      },
      {
        id: 202,
        name: 'dropdown',
        code: 'DROP_DOWN',
      },
      {
        id: 203,
        name: 'multi-select',
        code: 'MULTI_SELECT',
      },
    ],
    objectIds: [
      {
        id: 301,
        name: 'accounts',
        code: 'Account',
      },
      {
        id: 302,
        name: 'contacts',
        code: 'Contact',
      },
      {
        id: 303,
        name: 'leads/opportunities',
        code: 'Lead_Opportunity',
      },
    ],
  }),

  computed: {
    ...mapGetters({
      organization: 'Organization/getOrganization',
      customField: 'Settings/getCustomField',
      lookupCodes: 'Settings/getLookupsList',
    }),

    label: {
      get() {
        return this.$store.state.Settings.customFields.customField.label;
      },

      set(value) {
        return this.$store.commit('Settings/SET_FIELD_NAME', value);
      },
    },

    objectName: {
      get() {
        return this.$store.state.Settings.customFields.customField.objectName;
      },

      set(value) {
        return this.$store.commit('Settings/SET_FIELD_OBJECT_NAME', value);
      },
    },

    isRequired: {
      get() {
        return this.$store.state.Settings.customFields.customField.isRequired;
      },

      set(value) {
        return this.$store.commit('Settings/SET_FIELD_REQUIRED', value);
      },
    },

    fieldType: {
      get() {
        return this.$store.state.Settings.customFields.customField.type;
      },

      set(value) {
        return this.$store.commit('Settings/SET_FIELD_TYPE', value);
      },
    },

    lookups: {
      get() {
        return this.$store.state.Settings.customFields.customField.lookupName;
      },

      set(value) {
        return this.$store.commit('Settings/SET_FIELD_LOOKUPS', value);
      },
    },
  },

  async created() {
    try {
      if (this.hasPermissions('read_organizations')) {
        await this.fetchOrganization();
        await this.$store.dispatch('Settings/fetchLookups');
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    ...mapActions({
      fetchOrganization: 'Organization/fetchOrganization',
    }),

    resetFields() {
      this.$refs.customFieldsForm.reset();
    },

    updateField() {
      this.$refs.customFieldsTable.updateCustomField();
    },

    onClickOutsideTable() {
      if (this.isEditing) {
        this.isSavePromptActive = true;
      }
    },

  },
};
</script>

<style lang="css" scoped>
</style>
