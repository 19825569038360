<template>
  <div>
    <div class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-8">
            <validation-observer
              ref="lookupsForm"
              v-slot="{ invalid, handleSubmit }"
            >
              <form @submit.prevent="handleSubmit($emit('save-fields'))">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="lookupName"
                >
                  <b-field
                    horizontal
                    label="Lookup Name"
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors"
                    class="is-required"
                  >
                    <b-input
                      v-model="lookupName"
                      placeholder="Add lookup name"
                    />
                  </b-field>
                </validation-provider>
                <b-field
                  horizontal
                  label="Lookup Items"
                  class="is-required"
                >
                  <b-input
                    ref="lookupInput"
                    v-model="lookupItem"
                    placeholder="Add lookup item"
                    @keydown.native.enter.prevent="addItem"
                  />
                  <p class="control">
                    <b-button
                      type="is-info"
                      @click="addItem"
                    >
                      Add Item
                    </b-button>
                  </p>
                </b-field>

                <b-button
                  :disabled="invalid || lookupItems.length === 0"
                  type="is-info"
                  icon-left="plus"
                  aria-label="Add Lookups"
                  native-type="submit"
                >
                  Add Lookups
                </b-button>
              </form>
            </validation-observer>
          </div>
          <div class="column is-4">
            <div
              class="card is-primary"
              style="max-height: 300px; overflow-y: scroll !important;"
            >
              <div class="card-content">
                <div class="content">
                  <h4 class="has-text-white">
                    {{ lookupName || 'Lookup list' }}
                  </h4>
                </div>
                <div style="">
                  <b-field
                    v-for="(item, key) in lookupItems"
                    :key="key"
                  >
                    <b-tag
                      type="is-info"
                      closable
                      aria-close-label="Close tag"
                      size="is-medium"
                      rounded
                      @close="lookupItems.splice(key, 1)"
                    >
                      {{ item.value }}
                    </b-tag>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <lookups-table />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import LookupsTable from './LookupsTable.vue';

export default {
  name: 'LookupsForm',

  components: {
    ValidationObserver,
    ValidationProvider,
    LookupsTable,
  },

  data: () => ({
    isLoading: true,
    lookupItem: '',
  }),

  computed: {
    ...mapGetters({
      credentials: 'Settings/getCredentials',
      organization: 'Organization/getOrganization',
    }),

    lookupName: {
      get() {
        return this.$store.state.Settings.lookups.lookup.lookupName;
      },

      set(value) {
        return this.$store.commit('Settings/SET_LOOKUP_NAME', value);
      },
    },

    lookupItems: {
      get() {
        return this.$store.state.Settings.lookups.lookup.lookupItems;
      },

      set(value) {
        return this.$store.commit('Settings/SET_LOOKUP_ITEMS', value);
      },
    },
  },

  watch: {
    lookupName(value) {
      const listCopy = this.lookupItems.map((item) => ({ ...item, name: value, code: `${value.toLowerCase()}_${item.value}` }));
      this.$store.commit('Settings/SET_LOOKUP_ITEMS', listCopy);
    },
  },

  methods: {
    ...mapActions({
      fetchOrganization: 'Organization/fetchOrganization',
    }),

    resetFields() {
      this.$refs.lookupsForm.reset();
    },

    addItem(e) {
      e.preventDefault();
      const lookupObject = {
        organizationId: localStorage.getItem('organization_id'),
        name: this.lookupName,
        code: `${this.lookupName.toLowerCase()}_${this.lookupItem.toLowerCase()}`,
        value: this.lookupItem,
      };
      if (this.lookupItem === '') {
        this.$buefy.toast.open({
          message: 'Field is empty!',
          type: 'is-warning',
        });
      } else if (this.lookupItems.find((item) => item.code === lookupObject.code) === undefined) {
        this.$store.commit('Settings/SET_LOOKUP_ITEMS', [...this.lookupItems, lookupObject]);
        this.lookupItem = '';
        this.$refs.lookupInput.focus();
      } else {
        this.$buefy.toast.open({
          message: 'That item already exists!',
          type: 'is-warning',
        });
      }
    },

  },
};
</script>

<style lang="css" scoped>
  .tag {
    border: none;
  }
</style>
