<template>
  <div>
    <div class="section">
      <div class="container">
        <validation-observer
          ref="goalTypesForm"
          v-slot="{ invalid, handleSubmit }"
        >
          <form
            @submit.prevent="handleSubmit($emit('save-fields'))"
          >
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Goal Type"
            >
              <b-field
                horizontal
                label="Goal Type"
                :type="{ 'is-danger': errors[0] }"
                :message="errors"
                class="is-required"
              >
                <b-input
                  v-model="type"
                />
              </b-field>
            </validation-provider>

            <b-button
              v-if="isEditing === false"
              :disabled="invalid"
              type="is-info"
              icon-left="plus"
              aria-label="Add Field"
              native-type="submit"
            >
              Add Type
            </b-button>

            <b-button
              v-else-if="isEditing === true"
              :disabled="invalid"
              type="is-success"
              icon-left="plus"
              aria-label="Add Field"
              @click="updateField"
            >
              Update Type
            </b-button>
          </form>
        </validation-observer>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <goal-types-table
          ref="goalTypesTable"
          v-click-outside-dg="{
            handler: 'onClickOutsideTable',
            exclude: [
              'inlineEditingSavePromptGT',
              'goalTypesForm',
            ],
          }"
          @is-editing="(val) => isEditing = val"
          @reset-fields="resetFields"
        />
      </div>
    </div>

    <b-modal
      ref="inlineEditingSavePromptGT"
      v-model="isSavePromptActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="inline editing save modal"
      aria-modal
    >
      <template #default="props">
        <inline-editing-save-prompt
          @save="updateField"
          @close="props.close"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { InlineEditingSavePrompt } from '@/components/Shared';
import GoalTypesTable from './GoalTypesTable.vue';

export default {
  name: 'GoalTypesForm',

  components: {
    ValidationObserver,
    ValidationProvider,
    GoalTypesTable,
    InlineEditingSavePrompt,
  },

  data: () => ({
    isLoading: true,
    isEditing: false,
    isSavePromptActive: false,
  }),

  computed: {
    ...mapGetters({
      organization: 'Organization/getOrganization',
    }),

    type: {
      get() {
        return this.$store.state.Settings.goalTypes.goalType.type;
      },

      set(value) {
        return this.$store.commit('Settings/SET_GOAL_TYPE_NAME', value);
      },
    },

    isDefault: {
      get() {
        return this.$store.state.Settings.goalTypes.goalType.isDefault;
      },

      set(value) {
        return this.$store.commit('Settings/SET_GOAL_TYPE_DEFAULT', value);
      },
    },
  },

  async created() {
    try {
      if (this.hasPermissions('read_organizations')) {
        await this.fetchOrganization();
        await this.$store.dispatch('Settings/fetchLookups');
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    ...mapActions({
      fetchOrganization: 'Organization/fetchOrganization',
    }),

    resetFields() {
      this.$refs.goalTypesForm.reset();
    },

    updateField() {
      this.$refs.goalTypesTable.updateGoalType();
      this.resetFields();
    },

    onClickOutsideTable() {
      if (this.isEditing) {
        this.isSavePromptActive = true;
      }
    },

  },
};
</script>

<style lang="css" scoped>
</style>
