var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"}),_c('div',{staticClass:"level-right"},[_c('div',{staticClass:"level-item"},[(_vm.isEditing)?_c('b-button',{ref:"saveEntryButton",attrs:{"size":"is-small","icon-left":"content-save","type":"is-success"},on:{"click":_vm.updateLookup}},[_vm._v(" save ")]):_vm._e()],1)])]),_c('b-table',{directives:[{name:"click-outside-dg",rawName:"v-click-outside-dg",value:(_vm.onClickOutsideTable),expression:"onClickOutsideTable"}],ref:"lookupsTable",attrs:{"mobile-cards":false,"loading":_vm.loading,"data":_vm.lookUpsList.content,"checkable":_vm.customizations.isCheckable,"checked-rows":_vm.checkedRows,"paginated":"","backend-pagination":"","total":_vm.total,"per-page":_vm.perPage,"aria-next-label":"Next page","aria-previous-label":"Previous page","aria-page-label":"Page","aria-current-label":"Current page","narrowed":_vm.customizations.isNarrowed,"bordered":_vm.customizations.isBordered,"striped":_vm.customizations.isStriped,"sticky-header":_vm.customizations.isStickyHeaders,"scrollable":_vm.customizations.isScrollable,"backend-filtering":"","debounce-search":800,"backend-sorting":"","default-sort-direction":_vm.defaultSortOrder,"default-sort":[_vm.sortField, _vm.sortOrder],"sort-icon":_vm.sortIcon,"sort-icon-size":_vm.sortIconSize},on:{"update:checkedRows":function($event){_vm.checkedRows=$event},"update:checked-rows":function($event){_vm.checkedRows=$event},"sort":_vm.onSort,"filters-change":_vm.onFilter,"page-change":_vm.onPageChange,"update:selected":_vm.onClickOutsideEditing}},[_vm._l((_vm.columns),function(column,index){return _c('b-table-column',{key:index,attrs:{"label":column.label,"field":column.field,"searchable":column.searchable,"sortable":column.sortable,"visible":column.visible},scopedSlots:_vm._u([{key:"searchable",fn:function(props){return [[_c('b-input',{directives:[{name:"show",rawName:"v-show",value:(
              column.searchField === 'createdAt' ||
                column.searchField === 'updatedAt'
            ),expression:"\n              column.searchField === 'createdAt' ||\n                column.searchField === 'updatedAt'\n            "}],attrs:{"type":"date","icon":"calendar-month","size":"is-small"},model:{value:(props.filters[column.searchField]),callback:function ($$v) {_vm.$set(props.filters, column.searchField, $$v)},expression:"props.filters[column.searchField]"}}),_c('b-input',{directives:[{name:"show",rawName:"v-show",value:(
              column.searchField === 'name' ||
                column.searchField === 'value'
            ),expression:"\n              column.searchField === 'name' ||\n                column.searchField === 'value'\n            "}],attrs:{"placeholder":"Search...","icon":"search","size":"is-small"},model:{value:(props.filters[`${column.searchField}`]),callback:function ($$v) {_vm.$set(props.filters, `${column.searchField}`, $$v)},expression:"props.filters[`${column.searchField}`]"}}),_c('b-switch',{directives:[{name:"show",rawName:"v-show",value:(column.searchField === 'isActive'),expression:"column.searchField === 'isActive'"}],attrs:{"size":"is-small"},model:{value:(props.filters[`${column.searchField}`]),callback:function ($$v) {_vm.$set(props.filters, `${column.searchField}`, $$v)},expression:"props.filters[`${column.searchField}`]"}})]]}},{key:"default",fn:function(props){return [(column.field === 'actions')?[_c('div',{staticClass:"buttons"},[_c('b-button',{attrs:{"type":"is-info","size":"is-small"},on:{"click":function($event){return _vm.updateLookupStatus(props.row)}}},[_vm._v(" "+_vm._s(props.row.isActive ? 'Deactivate' : 'Activate')+" ")])],1)]:(column.searchField === 'name')?[_vm._v(" "+_vm._s(props.row[column.field])+" ")]:(column.field === 'value')?[_c('b-taglist',_vm._l((props.row.value.split(',')),function(value,lookupIndex){return _c('b-tag',{key:lookupIndex,attrs:{"type":"is-info"}},[_vm._v(" "+_vm._s(value)+" ")])}),1)]:(column.field === 'isActive')?[_vm._v(" "+_vm._s(props.row[column.field])+" ")]:(
            column.field === 'createdAt' || column.field === 'updatedAt'
          )?[_vm._v(" "+_vm._s(_vm._f("date")(props.row[column.field]))+" ")]:_vm._e()]}}],null,true)})}),_c('template',{slot:"footer"},[_c('table-footer',{attrs:{"first-item":_vm.firstItem,"page":_vm.page,"per-page":_vm.perPage,"total":_vm.total},scopedSlots:_vm._u([{key:"page-dropdown",fn:function(){return [_c('b-dropdown',{attrs:{"aria-role":"list","position":"is-top-left"},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}},[_c('b-button',{attrs:{"slot":"trigger","type":"is-info","size":"is-small","icon-right":"chevron-down"},slot:"trigger"},[_vm._v(" "+_vm._s(_vm.perPage)+" ")]),_vm._l((_vm.pages),function(pageCount,index){return [_c('b-dropdown-item',{key:index,attrs:{"value":pageCount}},[_vm._v(" "+_vm._s(pageCount)+" ")]),_c('hr',{key:pageCount,staticClass:"dropdown-divider"})]})],2)]},proxy:true}])})],1),_c('template',{slot:"empty"},[_c('empty-table',{attrs:{"table-name":"lookups"}})],1)],2),_c('b-modal',{ref:"inlineEditingSavePrompt",attrs:{"has-modal-card":"","trap-focus":"","destroy-on-hide":false,"aria-role":"dialog","aria-label":"inline editing save modal","aria-modal":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('inline-editing-save-prompt',{on:{"save":_vm.updateLookup,"close":props.close}})]}}]),model:{value:(_vm.isSavePromptActive),callback:function ($$v) {_vm.isSavePromptActive=$$v},expression:"isSavePromptActive"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }