<template>
  <div>
    <div class="section">
      <div class="container">
        <validation-observer
          ref="goalTypesForm"
          v-slot="{ invalid, handleSubmit }"
        >
          <form
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <b-field
              horizontal
              label="Leads/Opps Visible to all Users"
            >
              <b-switch
                v-model="oppsLeadsReadPermissions"
                :disabled="!isEditing"
              >
                {{ oppsLeadsReadPermissions }}
              </b-switch>
            </b-field>

            <!-- TODO: Enable when backend works -->
            <!-- <b-field
              horizontal
              label="Allow salespeople to export from Datagrids"
            >
              <b-switch
                v-model="allowSalesPeopleExport"
                :disabled="!isEditing"
              >
                {{ allowSalespeopleExport }}
              </b-switch>
            </b-field> -->

            <validation-provider
              name="fiscal year beginning"
            >
              <b-field
                horizontal
                label="Fiscal Year Beginning Month"
              >
                <div>
                  <b-select
                    v-model="fiscalYearStartMonth"
                    :disabled="!isEditing"
                    type="year"
                  >
                    <option
                      v-for="(month, index) in months"
                      :key="index"
                      :value="index+1"
                    >
                      {{ month }}
                    </option>
                  </b-select>
                </div>
              </b-field>
            </validation-provider>

            <b-field>
              <div class="buttons">
                <b-button
                  v-show="isEditing"
                  :disabled="invalid"
                  type="is-success"
                  aria-label="Save settings"
                  native-type="submit"
                >
                  Save Settings
                </b-button>
                <b-button
                  v-show="isEditing"
                  type="is-warning"
                  aria-label="cancel edit"
                  @click="onCancel"
                >
                  Cancel
                </b-button>
              </div>
            </b-field>
            <b-button
              v-show="!isEditing"
              type="is-info"
              aria-label="Edit settings"
              @click="isEditing = true"
            >
              Edit Settings
            </b-button>
          </form>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'SettingsCustomizationsForm',

  components: {
    ValidationObserver,
    ValidationProvider,
  },

  data: () => ({
    isLoading: true,
    isEditing: false,
    isSavePromptActive: false,
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
  }),

  computed: {
    ...mapGetters({
      organization: 'Organization/getOrganization',
    }),

    oppsLeadsReadPermissions: {
      get() {
        return this.$store.state.Settings.customizations.allRead;
      },

      set(value) {
        return this.$store.commit('Settings/SET_READ_CUSTOMIZATIONS', value);
      },
    },

    allowSalesPeopleExport: {
      get() {
        return this.$store.state.Settings.customizations.allowSalesPeopleExport;
      },

      set(value) {
        return this.$store.commit('Settings/SET_ALLOW_SALESPEOPLE_EXPORT', value);
      },
    },

    fiscalYearStartMonth: {
      get() {
        return this.$store.state.Settings.customizations.fiscalStartMonth;
      },

      set(value) {
        return this.$store.commit('Settings/SET_FISCAL_YEAR_START', value);
      },
    },
  },

  async created() {
    try {
      if (this.hasPermissions('read_organizations')) {
        await this.$store.dispatch('Settings/fetchCustomizations');
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    ...mapActions({
      fetchOrganization: 'Organization/fetchOrganization',
    }),

    onSubmit() {
      this.$emit('save-settings');
      this.isEditing = false;
    },

    async onCancel() {
      await this.$store.dispatch('Settings/fetchCustomizations');
      this.isEditing = false;
    },

  },
};
</script>

<style lang="css" scoped>
</style>
