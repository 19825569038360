var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('validation-observer',{ref:"goalTypesForm",scopedSlots:_vm._u([{key:"default",fn:function({ invalid, handleSubmit }){return [_c('form',{on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.$emit('save-fields'))}}},[_c('validation-provider',{attrs:{"rules":"required","name":"Goal Type"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-field',{staticClass:"is-required",attrs:{"horizontal":"","label":"Goal Type","type":{ 'is-danger': errors[0] },"message":errors}},[_c('b-input',{model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)]}}],null,true)}),(_vm.isEditing === false)?_c('b-button',{attrs:{"disabled":invalid,"type":"is-info","icon-left":"plus","aria-label":"Add Field","native-type":"submit"}},[_vm._v(" Add Type ")]):(_vm.isEditing === true)?_c('b-button',{attrs:{"disabled":invalid,"type":"is-success","icon-left":"plus","aria-label":"Add Field"},on:{"click":_vm.updateField}},[_vm._v(" Update Type ")]):_vm._e()],1)]}}])})],1)]),_c('div',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('goal-types-table',{directives:[{name:"click-outside-dg",rawName:"v-click-outside-dg",value:({
          handler: 'onClickOutsideTable',
          exclude: [
            'inlineEditingSavePromptGT',
            'goalTypesForm',
          ],
        }),expression:"{\n          handler: 'onClickOutsideTable',\n          exclude: [\n            'inlineEditingSavePromptGT',\n            'goalTypesForm',\n          ],\n        }"}],ref:"goalTypesTable",on:{"is-editing":(val) => _vm.isEditing = val,"reset-fields":_vm.resetFields}})],1)]),_c('b-modal',{ref:"inlineEditingSavePromptGT",attrs:{"has-modal-card":"","trap-focus":"","destroy-on-hide":false,"aria-role":"dialog","aria-label":"inline editing save modal","aria-modal":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('inline-editing-save-prompt',{on:{"save":_vm.updateField,"close":props.close}})]}}]),model:{value:(_vm.isSavePromptActive),callback:function ($$v) {_vm.isSavePromptActive=$$v},expression:"isSavePromptActive"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }