<template>
  <dashboard-layout>
    <template #content>
      <page-header
        name="Settings"
        icon="cog"
      />

      <section class="section">
        <div class="container">
          <b-breadcrumb size="is-small">
            <b-breadcrumb-item
              tag="router-link"
              to="/profile"
            >
              Home
            </b-breadcrumb-item>
            <b-breadcrumb-item
              tag="router-link"
              to="/settings"
              active
            >
              Settings
            </b-breadcrumb-item>
          </b-breadcrumb>

          <b-tabs
            v-model="activeTab"
          >
            <b-tab-item
              label="Outlook"
            >
              <div class="container">
                <div class="columns">
                  <div class="column is-three-fifths">
                    <div
                      v-if="organization.azureCredentials.length >= 1"
                      class="box"
                    >
                      <b-field
                        horizontal
                        label="Client ID"
                      >
                        <b-input
                          type="password"
                          password-reveal
                          disabled
                          :value="organization.azureCredentials[0].clientId"
                        />
                      </b-field>

                      <b-field
                        horizontal
                        label="Tenant ID"
                      >
                        <b-input
                          type="password"
                          password-reveal
                          disabled
                          :value="organization.azureCredentials[0].tenantId"
                        />
                      </b-field>

                      <b-field horizontal>
                        <div class="buttons">
                          <b-button
                            type="is-danger"
                            icon-left="delete"
                            @click="deleteCredentials(organization.azureCredentials[0].id)"
                          >
                            Remove
                          </b-button>

                          <b-button
                            type="is-primary"
                            icon-left="handshake"
                            @click="grantConsent"
                          >
                            grant consent
                          </b-button>

                          <b-button @click="simulateCallback">
                            simulate login
                          </b-button>
                        </div>
                      </b-field>
                    </div>

                    <settings-form
                      v-else
                      @save-settings="saveSettings"
                    />
                  </div>
                </div>
              </div>
            </b-tab-item>
            <b-tab-item
              label="Custom Fields"
            >
              <div>
                <custom-fields-form
                  ref="customFieldsForm"
                  @save-fields="createCustomField"
                  @move-to-lookups="activeTab = 2"
                />
              </div>
            </b-tab-item>
            <b-tab-item
              label="Lookups"
            >
              <lookups-form
                ref="lookupsForm"
                @save-fields="createLookups"
              />
            </b-tab-item>
            <b-tab-item
              label="Goal Types"
            >
              <goal-types-form
                ref="goalTypesForm"
                @save-fields="createGoalType"
              />
            </b-tab-item>
            <b-tab-item
              label="Settings"
            >
              <settings-customizations-form
                ref="settingsCustomizationsForm"
                @save-settings="saveCustomizationSettings"
              />
            </b-tab-item>
            <b-tab-item
              label="Linked IDs"
            >
              <LinkedIdsForm
                ref="linkedIdsForm"
                @save-fields="createLinkedId"
              />
              <LinkedIdsTable />
            </b-tab-item>
          </b-tabs>
        </div>
      </section>
    </template>
  </dashboard-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { PageHeader } from '@/components/Shared';
import SettingsForm from '@/components/SettingsForm.vue';
import {
  CustomFieldsForm,
  LinkedIdsForm,
  LinkedIdsTable,
  LookupsForm,
  GoalTypesForm,
  SettingsCustomizationsForm,
} from '@/components/Settings';

export default {
  name: 'Settings',

  components: {
    DashboardLayout: () => import('../layouts/DashboardLayout.vue'),
    PageHeader,
    SettingsForm,
    CustomFieldsForm,
    LinkedIdsForm,
    LinkedIdsTable,
    LookupsForm,
    GoalTypesForm,
    SettingsCustomizationsForm,
  },

  data: () => ({
    isLoading: true,
    activeTab: 1,
  }),

  computed: {
    ...mapGetters({
      credentials: 'Settings/getCredentials',
      organization: 'Organization/getOrganization',
      customField: 'Settings/getCustomField',
      lookupItems: 'Settings/getLookupItems',
      customizations: 'Settings/getCustomizations',
      goalType: 'Settings/getGoalType',
      linkedId: 'Settings/getLinkedId',
    }),
  },

  async created() {
    try {
      if (this.hasPermissions('read_organizations')) {
        await this.fetchOrganization();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    ...mapActions({
      fetchOrganization: 'Organization/fetchOrganization',
    }),

    async createCustomField() {
      this.isLoading = true;
      try {
        if (this.customField.lookupName === null) {
          this.customField.lookupName = '';
        }
        if (this.customField.type === 'DROP_DOWN') {
          this.customField.type = 'Dropdown';
        }
        if (this.customField.type === 'TEXT_INPUT') {
          this.customField.type = 'Text Input';
        }
        if (this.customField.type === 'MULTI_SELECT') {
          this.customField.type = 'Multi Select';
        }
        await this.$store.dispatch('Settings/createCustomField', this.customField);
        this.$store.commit('Settings/CLEAR_CUSTOM_FIELD');
        this.$refs.customFieldsForm.resetFields();
      } catch (error) {
        console.error(error);
        if (error.status === 'Conflict') {
          this.$store.commit('Settings/CLEAR_CUSTOM_FIELD');
          this.$refs.customFieldsForm.resetFields();
          this.$buefy.toast.open({
            message: error.message,
            type: 'is-warning',
          });
        }
      } finally {
        this.isLoading = false;
        await this.$store.dispatch('DataGrids/fetchCustomFields', {
          page: 0,
          perPage: 25,
          sort: ['updatedAt', 'desc'],
          globalSearch: '',
          filters: '',
        });
      }
      return '';
    },

    async createLookups() {
      this.isLoading = true;
      try {
        await this.lookupItems.forEach((item) => {
          this.$store.dispatch('Settings/createLookup', item);
          this.$store.dispatch('DataGrids/fetchLookups', {
            page: 0,
            perPage: 25,
            sort: ['updatedAt', 'desc'],
            globalSearch: '',
            filters: '',
          });
          this.$store.dispatch('Settings/fetchLookups');
        });
        this.$refs.lookupsForm.resetFields();
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    async createGoalType() {
      this.isLoading = true;
      try {
        await this.$store.dispatch('Settings/createGoalType', this.goalType);
        await this.$store.dispatch('DataGrids/fetchGoalTypes', {
          page: 0,
          perPage: 25,
          sort: ['updatedAt', 'desc'],
          globalSearch: '',
          filters: '',
        });
        // this.$store.dispatch('Settings/fetchGoalType');
        this.$store.commit('Settings/CLEAR_GOAL_TYPE');
        this.$refs.goalTypesForm.resetFields();
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    async createLinkedId() {
      this.isLoading = true;
      try {
        await this.$store.dispatch('Settings/createLinkedId', this.linkedId);
        await this.$store.dispatch('DataGrids/fetchLinkedIds', {
          page: 0,
          perPage: 25,
          sort: ['updatedAt', 'desc'],
          globalSearch: '',
          filters: '',
        });
        this.$store.commit('Settings/CLEAR_LINKED_ID');
        this.$refs.linkedIdsForm.resetFields();
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    saveSettings() {
      return this.isEditing ? this.updateCredentials() : this.createCredentials();
    },

    saveCustomizationSettings() {
      return this.updateCustomizationsSettings();
    },

    grantConsent() {
      this.$buefy.dialog.confirm({
        title: 'Settings Consent',
        message: 'Agree',
        cancelText: 'Disagree',
        confirmText: 'Agree',
        type: 'is-success',
        onConfirm: async () => {
          try {
            await this.$store.dispatch(
              'Settings/consentCredentials',
              this.organization.azureCredentials[0].clientId,
            );
          } catch (error) {
            console.error(error);
          }
        },
      });
    },

    async updateCustomizationsSettings() {
      this.isLoading = true;
      try {
        // call api to update settings
        await this.$store.dispatch('Settings/updateCustomizations', this.customizations);
        this.$buefy.toast.open({
          message: 'Settings saved successfully',
          type: 'is-success',
        });
      } catch (error) {
        console.error(error);
        this.$buefy.toast.open({
          message: error.message,
          type: 'is-danger',
        });
      } finally {
        this.isLoading = false;
      }
    },

    async createCredentials() {
      this.isLoading = true;
      try {
        await this.$store.dispatch('Settings/createCredentials', this.credentials);
        await this.$store.dispatch('Organization/fetchOrganization');
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    deleteCredentials(credentialsId) {
      this.$buefy.dialog.confirm({
        title: 'Deleting credentials',
        message: 'Are you sure you want to <b>delete</b> your credentials? This action cannot be undone.',
        confirmText: 'Delete Credentials',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          this.isLoading = true;
          try {
            await this.$store.dispatch('Settings/deleteCredentials', credentialsId);
            await this.$store.dispatch('Organization/fetchOrganization');
          } catch (error) {
            console.error(error);
          } finally {
            this.isLoading = false;
          }
        },
      });
    },

    simulateCallback() {
      this.$store.dispatch('Settings/azureCallback', {
        tenant: this.organization.azureCredentials[0].tenantId,
        state: 12345,
        adminConsent: true,
      });
    },

  },
};
</script>

<style lang="css" scoped>
</style>
