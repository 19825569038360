<template>
  <div>
    <div class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-8">
            <validation-observer
              ref="linkedIdsForm"
              v-slot="{ invalid, handleSubmit }"
            >
              <form @submit.prevent="handleSubmit($emit('save-fields'))">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Alternate Sales ID"
                >
                  <b-field
                    horizontal
                    label="Alternate Sales ID"
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors"
                    class="is-required"
                  >
                    <b-input v-model="salesID" />
                  </b-field>
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  name="Sales ID Description"
                >
                  <b-field
                    horizontal
                    label="Sales ID Description"
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors"
                  >
                    <b-input
                      v-model="description"
                      type="textarea"
                    />
                  </b-field>
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Salesperson"
                >
                  <b-field
                    horizontal
                    label="Salesperson"
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors"
                    class="is-required"
                  >
                    <b-autocomplete
                      v-model="salesperson"
                      icon-right="search"
                      expanded
                      field="fullName"
                      :loading="isLoading"
                      :data="salespersonSearchResults"
                      @typing="getSalespersonsAsyncData"
                      @select="(option) => (selectedUserId = option)"
                    >
                      <template slot-scope="props">
                        <div class="media">
                          <div class="media-content">
                            {{ props.option.fullName.trim() }}
                            <br>
                            <small>
                              email: {{ props.option.email }} <br>
                              Phone: {{ props.option.phoneCell }}
                            </small>
                          </div>
                        </div>
                      </template>

                      <template #empty>
                        No results for {{ salesperson.fullName }}
                      </template>
                    </b-autocomplete>
                  </b-field>
                </validation-provider>

                <b-button
                  :disabled="invalid"
                  type="is-info"
                  icon-left="plus"
                  aria-label="Add Sales ID"
                  native-type="submit"
                >
                  Add Sales ID
                </b-button>
              </form>
            </validation-observer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

import debounce from '@/utils/debounce';

export default {
  name: 'LinkedIdsForm',

  components: {
    ValidationObserver,
    ValidationProvider,
  },

  data: () => ({
    isLoading: true,
    lookupItem: '',
    salespersonSearchResults: [],
    selectedUserId: null,
    salesperson: '',
  }),

  computed: {
    ...mapGetters({
      credentials: 'Settings/getCredentials',
      organization: 'Organization/getOrganization',
    }),

    salesID: {
      get() {
        return this.$store.state.Settings.linkedIds.linkedId.salesID;
      },

      set(value) {
        return this.$store.commit('Settings/SET_LINKED_ID_SALES_ID', value);
      },
    },

    description: {
      get() {
        return this.$store.state.Settings.linkedIds.linkedId.description;
      },

      set(value) {
        return this.$store.commit('Settings/SET_LINKED_ID_DESCRIPTION', value);
      },
    },
  },

  watch: {
    selectedUserId(value) {
      this.salesperson = value.fullName;
      return this.$store.commit('Settings/SET_LINKED_ID_USER_ID', value.userId);
    },
  },

  methods: {
    ...mapActions({
      fetchOrganization: 'Organization/fetchOrganization',
    }),

    resetFields() {
      this.salesperson = '';
      this.$refs.linkedIdsForm.reset();
    },

    getSalespersonsAsyncData: debounce(async function (payload) {
      this.isLoading = true;
      try {
        this.salespersonSearchResults = await this.$store.dispatch('Search/searchUsers', payload);
      } catch (error) {
        this.salespersonSearchResults = [];
      } finally {
        this.isLoading = false;
      }
    }, 500),
  },
};
</script>

<style lang="css" scoped>
  .tag {
    border: none;
  }
</style>
