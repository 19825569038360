<template>
  <validation-observer
    ref="settingsForm"
    v-slot="{ handleSubmit }"
  >
    <form @submit.prevent="handleSubmit($emit('save-settings'))">
      <validation-provider
        v-slot="{ errors }"
        rules="required"
        name="client ID"
      >
        <b-field
          horizontal
          label="Client ID"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          class="is-required"
        >
          <b-input v-model="clientId" />
        </b-field>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        rules="required"
        name="tenant ID"
      >
        <b-field
          horizontal
          label="Tenant ID"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          class="is-required"
        >
          <b-input v-model="tenantId" />
        </b-field>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        rules="required"
        name="client secret"
      >
        <b-field
          horizontal
          label="Client Secret"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          class="is-required"
        >
          <b-input v-model="secretKey" />
        </b-field>
      </validation-provider>

      <b-field horizontal>
        <b-button
          native-type="submit"
          type="is-info"
        >
          save settings
        </b-button>
      </b-field>
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'SettingsForm',

  components: {
    ValidationObserver,
    ValidationProvider,
  },

  computed: {
    clientId: {
      get() {
        return this.$store.state.Settings.credentials.clientId;
      },

      set(value) {
        return this.$store.commit('Settings/SET_CREDENTIALS_CLIENT_ID', value);
      },
    },

    tenantId: {
      get() {
        return this.$store.state.Settings.credentials.tenantId;
      },

      set(value) {
        return this.$store.commit('Settings/SET_CREDENTIALS_TENANT_ID', value);
      },
    },

    secretKey: {
      get() {
        return this.$store.state.Settings.credentials.secretKey;
      },

      set(value) {
        return this.$store.commit('Settings/SET_CREDENTIALS_SECRET_KEY', value);
      },
    },
  },
};
</script>

<style>

</style>
