import debounce from '@/utils/debounce';

export default {
  data: () => ({
    searchLoading: false,
    searchTimeout: 5000,
    selectedAccount: null,
    selectedContact: null,
    selectedUser: null,
    accountsSearchResults: [],
    contactsSearchResults: [],
    usersSearchResults: [],
  }),

  methods: {
    getAccountsAsyncData: debounce(async function (token) {
      try {
        this.accountsSearchResults = await this.$store.dispatch('Search/searchAccounts', token);
      } catch (error) {
        this.accountsSearchResults = [];
      }
    }, 500),

    getUsersAsyncData: debounce(async function (token) {
      try {
        this.usersSearchResults = await this.$store.dispatch('Search/searchUsers', token);
      } catch (error) {
        this.usersSearchResults = [];
      }
    }, 500),

    getContactsAsyncData: debounce(async function (token) {
      try {
        const response = await this.$store.dispatch('Contacts/fetchAccountContactList', {
          page: 0,
          size: 1000000,
          sort: ['updatedAt', 'desc'],
          filters: `&${new URLSearchParams({ contactName: token }).toString()}`,
        });
        this.contactsSearchResults = response;
      } catch (error) {
        this.contactsSearchResults = [];
      }
    }, 500),
  },
};
